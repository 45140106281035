.container {
    margin: 16px;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    flex-grow: 1;
}

.container hr {
    width: 100%;
}

.container h2 {
    margin: 0;
}

.container h3 {
    margin: 0;
}

.choices {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    grid-template-rows: 1fr 1fr 1fr;
}

.chips > * {
    margin: 8px 8px;
}

.actionsContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.actionsContainer hr {
    margin-top: auto;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.titleWithPagination {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.tobacooPicker {
    min-height: calc(min(100vh,1024px) - 64px);
}
