.container {
    border-radius: var(--border-radius);
    background-color: var(--color-overlay);
}

.warningContainer {
    background-color: var(--color-red-background);
}

.header {
    min-height: 28px;
    padding: 8px 8px 4px 8px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-overlay);
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
}

.content {
    padding: 8px;
    display: grid;
    grid-gap: 16px;
    position: relative;
    color: var(--color-subtle);
}

.counter {
    display: grid;
    grid-gap: 4px;
    grid-template-areas: "statusLeft statusRight"
                         "label          minutes"
                         "progress      progress";
    align-items: end;
}

.statusLeft {
    grid-area: statusLeft;
    display: flex;
    gap: 8px;
    min-height: 28px;
}

.statusRight {
    grid-area: statusRight;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    min-height: 28px;
}

.label {
    grid-area: label;
}

.minutes {
    text-align: right;
    grid-area: minutes;
    white-space: nowrap;
}

.mainCount {
    font-size: 200%;
    font-weight: lighter;
    line-height: 0.96;
}

.value {
    color: var(--color-text);
}

.progress {
    grid-area: progress;
}

.needsService {
    color: var(--color-red);
    font-weight: normal;
}

.active {
    color: var(--color-green);
}

div.endingBar {
    background-color: var(--color-red);
}

div.endingBarDimmed {
    background-color: var(--color-red-dimmed);
}

div.startingBar {
    background-color: var(--color-subtle);
}

div.startingBarDimmed {
    background-color: var(--color-subtle-dimmed);
}

