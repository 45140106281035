.status {
    position: absolute;
    left: 40px;
    top: 4px;
    background: black;
    border-radius: 16px;
    font-size: 20px;
    width: 24px;
    height: 24px;
    line-height: 32px;
}

.menu {
    max-height: calc(100vh - 16px);
    overflow: auto;
}

.location {
    padding: 16px;
    background-color: var(--color-overlay);
    display: grid;
    justify-items: center;
    grid-gap: 8px;
}

.lounge {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    align-items: center;
}

.loungeItem {
    margin-left: 16px;
    font-weight: lighter;
}

.loungeItemSelected {
    margin-left: 16px;
    font-weight: bolder;
}

.username {
    font-weight: bold;
}

.email {
    font-size: smaller;
    font-style: italic;
    margin-bottom: 8px;
}

.statusDisconnected {
    -webkit-box-shadow: inset 0 0 4px 1px var(--color-red);
    -moz-box-shadow: inset 0 0 4px 1px var(--color-red);
    box-shadow: inset 0 0 4px 1px var(--color-red);
}

.disconnectedUser {
    filter: saturate(0.25) brightness(0.75);
}
