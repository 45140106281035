.container {
    border-radius: var(--border-radius);
    background-color: var(--color-overlay);
}

.default {
    border: 1px dashed var(--color-primary);
    background-color: var(--color-primary-dark);
}

.default .header {
    font-size: 18px;
    line-height: 1.5;
    white-space: normal;
    word-break: break-word;
}

.default .header .label {
    opacity: 0.5;
    font-weight: lighter;
}

.header {
    min-height: 28px;
    padding: 8px 8px 4px 8px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-overlay);
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
}

.content {
    padding: 8px;
    display: grid;
    grid-gap: 16px;
}
