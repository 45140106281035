.center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    height: 70%;
    display: grid;
    /*grid-row-gap: 64px;*/
    justify-items: center;
    align-content: space-around;
}

.logo {
    width: 70vw;
    max-width: 1000px;
}
