.container {
    display: grid;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 16px;
}

.card {
    display: grid;
    grid-gap: 16px;
    padding: 16px 32px;
    justify-self: center;
    justify-items: center;
}

.title {
    text-align: center;
}
