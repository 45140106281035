.center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: grid;
    grid-row-gap: 64px;
    justify-items: center;
}

.logo {
    width: 70vw;
    max-width: 1000px;
}



