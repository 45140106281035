.container {
    background-color: var(--color-overlay);
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
}

.pageTitle {
    opacity: 0.5;
}

.pageControls {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr auto;
    align-items: center;
    border-right: 2px solid #FFFFFF10;
}

.menuItem {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
}
