.container {
    width: 100%;
}

.container * {
    text-transform: none;
}

.alert {
    animation: blinking 2000ms infinite;
}

.button {
    width: 100%;
    display: grid;
}

.status {
    font-size: 24px;
}

.subtitle {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes blinking {
    0% { filter:
            drop-shadow(0px 0px 0px #FF000000)
            drop-shadow(0px 0px 0px #FF000000)
            drop-shadow(0px 0px 0px #FF000000);
    }
    50% { filter:
            drop-shadow(0px 0px 2px #FF0000FF)
            drop-shadow(0px 0px 4px #FF0000B0)
            drop-shadow(0px 0px 8px #FF000080);
    }
}
