.container {
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    grid-gap: 16px;
    flex-grow: 1;
}

.container hr {
    width: 100%;
}

.container h2 {
    margin: 0;
}

.container h3 {
    margin: 0;
}

.buttonContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin: 32px;
}

.button {
    padding: 16px;
}

.buttonContainerRow {
    align-self: center;
    display: flex;
    column-gap: 64px;
    margin: 0px;
    height: 10vh;
}


.button_child1 {
    width: 50%;
    text-align: right;
}

.button_child2 {
    width: 50%;
}

.actionsContainer {
    display: flex;
    flex-direction: column;
}

.actionsContainer hr {
    margin-top: auto;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.subtitle {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

