.container {
    height: 100%;
    display: grid;
    grid-gap: 16px;
    grid-template-rows: auto minmax(0, 1fr);
}

.menu {
    background-color: var(--color-overlay);
}

.page {
    padding: 0 16px 16px 16px;
    overflow: auto;
}
