.glowing {
    animation: glowing 12000ms infinite ease-out;
}

@keyframes glowing {
    0% { filter:
            drop-shadow(0px 0px 0px #EF762F00)
            drop-shadow(0px 0px 0px #EF762F00)
            drop-shadow(0px 0px 0px #EF762F00)
            saturate(1);
    }
    10% { filter:
            drop-shadow(0px 0px 2px #7F0A00FF)
            drop-shadow(0px 0px 4px #7F0A00A0)
            drop-shadow(0px 0px 8px #7F0A0080)
            saturate(4);
    }
    20% { filter:
            drop-shadow(0px 0px 2px #EF762FFF)
            drop-shadow(0px 0px 3px #EF762FC0)
            drop-shadow(0px 0px 6px #EF762FA0)
            saturate(2);
    }
    50% { filter:
            drop-shadow(0px 0px 0px #EF762F00)
            drop-shadow(0px 0px 0px #EF762F00)
            drop-shadow(0px 0px 0px #EF762F00)
            saturate(1);
    }
}

.active {
    animation: active 5000ms infinite ease-in-out;
}

@keyframes active {
    0% { filter:
            blur(0px)
            drop-shadow(0px 0px 2px hsla(120, 100%, 67%, 0.0));
    }
    50% { filter:
            blur(2px)
            drop-shadow(0px 0px 2px hsla(120, 100%, 67%, 1.0));
    }
    100% { filter:
            blur(0px)
            drop-shadow(0px 0px 2px hsla(120, 100%, 67%, 0.0));
    }
}
