:root {
    --color-background: hsl(240, 28%, 10%);
    --color-text: hsla(20, 100%, 98%, 0.95);
    --color-subtle: hsla(20, 100%, 98%, 0.5);
    --color-subtle-dimmed: hsla(20, 100%, 98%, 0.2);
    --color-overlay: #FFFFFF08;
    --color-primary: hsl(35, 80%, 58%);
    --color-primary-dark: hsl(35, 80%, 10%);
    --color-green: hsl(120, 100%, 67%);
    --color-red: hsl(0, 100%, 67%);
    --color-red-dimmed: hsla(0, 100%, 67%, 0.5);
    --color-red-background: hsla(0, 100%, 67%, 0.2);
    --border-radius: 4px;
    --scrollbar-padding: 8px;
}

html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background: var(--color-background) url("../src/assets/noise-640x480.png");
    color: var(--color-text);
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius);
    background: #FFFFFF20;
}

::-webkit-scrollbar-thumb:hover {
    background: #FFFFFF40;
}

::-webkit-scrollbar-thumb:active {
    background: #FFFFFF80;
}

::-webkit-scrollbar-track {
    border-radius: var(--border-radius);
    background: #FFFFFF10;
}

::-webkit-scrollbar-track:hover, ::-webkit-scrollbar-track:active {
    background: #FFFFFF20;
}
